import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import axios from 'axios';

import { faTimes, faLock, faInfoCircle, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Transfer.css';
import { theme } from '../../../utils/theme';
import logo from '../../../utils/assets/logo.png';

function Transfer() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        account: null,
        amount: '',
        beneficiary: null,
        beneficiaryNumber: '',
        beneficiaryName: '',
        description: '',
        pin: '',
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        isChecking: false,
        refresh: false
    };

    const [data, setData] = useState(initialState);

    const [ showModal, setShowModal ] = useState(false);
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        // Account
        axios.get(`${apiBaseUrl}/account/user/${user._id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            // console.log(res.data)
            setData({
                ...data,
                account: res.data.account
            });
        })
        .catch(err => console.log(err))
        
    }, [apiBaseUrl, data.refresh]);

    const fetchBeneficiaryAccount = async (number) => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isChecking: true,
            errorMessage: null,
            successMessage: null
        });

        // Account
        const res = await axios.get(`${apiBaseUrl}/account/acct/${number}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        if (res) {
            return res.data.account;
        }

    }

    const handleChange = async (e) => {
        e.preventDefault();

        if (e.target.name === 'beneficiaryNumber') { 
            if (e.target.value.length === 10) {
                const beneficiary = await fetchBeneficiaryAccount(e.target.value);

                if (beneficiary) {
                    setData({
                        ...data,
                        [e.target.name]: e.target.value,
                        isChecking: false,
                        beneficiary: beneficiary,
                        beneficiaryNumber: beneficiary.accountNumber,
                        beneficiaryName: beneficiary.accountName,
                        errorMessage: null,
                        successMessage: null
                    });
                } else {
                    setData({
                        ...data,
                        isChecking: false,
                        errorMessage: 'Invalid Account Number',
                        successMessage: null
                    });
                }

            } else {
                setData({
                    ...data,
                    beneficiaryNumber: e.target.value,
                    beneficiaryName: ''
                });
            }

        } else {

            setData({
                ...data,
                [e.target.name]: e.target.value
            });
        }

    };

    const sendOTP = (e) => {
        e.preventDefault();

        const user = cookie.session.user;
        const token = cookie.session.token;

        // setData({
        //     ...data,
        //     isSubmitting: true,
        //     errorMessage: null,
        //     successMessage: null
        // });

        if (data.account && data.beneficiaryNumber !== '' && data.beneficiaryName !== '') {
            
            if (data.account.accountNumber !== data.beneficiaryNumber) {

                if (data.amount > 0) {

                    if (data.account.balance >= data.amount) {
                        openModal();
                
                        // axios.patch(`${apiBaseUrl}/user/${data.account.user._id}/send-otp`, {},
                        // {
                        //     headers: {
                        //         'Authorization': 'Bearer ' + token
                        //     }
                        // })
                        // .then(res => {
                        //     // console.log(res.data)
                        //     setData({
                        //         ...data,
                        //         isSubmitting: false,
                        //         successMessage: null,
                        //         errorMessage: null,
                        //     });
                        // })
                        // .catch(error => {
                        //     // console.log(error)
                        //     setData({
                        //         ...data,
                        //         isSubmitting: false,
                        //         errorMessage: "Something went wrong. Couldn't send OTP. Try again later.",
                        //         successMessage: null
                        //     });
                            
                        // });
    
                    } else {
    
                        setData({
                            ...data,
                            isSubmitting: false,
                            errorMessage: 'Insufficient Fund...',
                            successMessage: null
                        });
                    }
    
                } else {
                    
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: 'Input a valid amount',
                        successMessage: null
                    });
                }
            } else {

                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'You cannot send to your account!',
                    successMessage: null
                });
            }

        } else {

            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty field',
                successMessage: null
            });
        }

    };

    const transferFund = (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        if (data.account.balance >= data.amount) {
            if (data.account && data.beneficiaryNumber !== '' && data.beneficiaryName !== '' && !(isNaN(data.amount)) && data.pin !== '' ) {
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null,
                    successMessage: null
                });
        
                axios.post(`${apiBaseUrl}/transaction`, {
                    senderId: data.account._id,
                    beneficiaryId: data.beneficiary._id,
                    amount: data.amount,
                    description: data.description,
                    pin: data.pin
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(res => {
                    // console.log(res.data)
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Transaction submitted',
                        errorMessage: null,
                        amount: '',
                        beneficiaryNumber: '',
                        beneficiaryName: '',
                        description: '',
                        pin: '',
                        refresh: !data.refresh
                    });

                    closeModal();
                    openSuccessModal();
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: 'Transaction failed! *1. Put in the correct pin. If you do not have one, create a new PIN at Settings. *2. Try again later.',
                        successMessage: null
                    });
                    
                });
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'Empty and/or Invalid fields',
                    successMessage: null
                });
            }
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Insufficient Fund...',
                successMessage: null
            });
        }

    };

    const openModal = () => {

        setShowModal(true);

    }

    const openSuccessModal = () => {

        setShowSuccessModal(true);

    }

    const closeModal = () => {

        setShowModal(false);
        setShowSuccessModal(false);
        
    }

    return (
        <DashboardLayout activePage={2}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Transfer To Trustcorp Bank</div>
                    </div>
                </section>

                {/* Form */}
                <section className="form-section">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-7 mt-3'>
                                <div className="d-flex justify-content-end">
                                    <p className="mb-0 mr-2">Bal.</p>
                                    <h5 className="mb-0">${data.account ? data.account.balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}</h5>
                                </div>
                                {
                                    data.successMessage && (
                                        <div className="alert alert-success">{data.successMessage} <FontAwesomeIcon icon={faCheck} /></div>
                                    )
                                }
                                {
                                    data.errorMessage && (
                                        <div className="alert alert-danger">{data.errorMessage}</div>
                                    )
                                }
                                {
                                    data.account ? 
                                        data.account.user ? 
                                            data.account.user.freeze ? 
                                                (
                                                    <div className="mt-5 p-2 d-flex justify-content-center">
                                                        <h5><FontAwesomeIcon icon={faInfoCircle} color={theme.info} /> Your account has been temporary locked <FontAwesomeIcon icon={faLock} /></h5>
                                                    </div>
                                                )
                                                : (
                                                <form className="w-100" onSubmit={sendOTP} >
                                                        <div className="form-group">
                                                            <label className="">Account</label>
                                                            <input type="text" className="form-control" value={data.account ? data.account.accountNumber : null} readOnly />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Benificiary Account Number</label>
                                                            <input type="text" name="beneficiaryNumber" className="form-control" onChange={handleChange} value={data.beneficiaryNumber} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Benificiary Account Name</label>
                                                            <input type="text" name="beneficiaryName" className="form-control" value={data.beneficiaryName} readOnly />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Amount</label>
                                                            <input type="number" name="amount" value={data.amount} className="form-control" onChange={handleChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="">Description</label>
                                                            <input type="text" name="description" value={data.description} className="form-control" onChange={handleChange} />
                                                        </div>
                                                        {/* {
                                                            (data.account && data.beneficiaryNumber && data.beneficiaryName && data.amount) ? 
                                                                (
                                                                    <div className="form-group">
                                                                        <label className="">PIN</label>
                                                                        <input type="password" name="pin" className="form-control" onClick={() => openModal()} value={data.pin} readOnly />
                                                                    </div>
                                                                )
                                                                : null
                                                        } */}
                                                        <p className="text-right">
                                                            <button type="submit" className="btn btn-primary">TRANSFER FUND</button>
                                                        </p>
                                                    </form>
                                                )
                                            : null
                                        : null
                                }
                            </div>
                            <div className='col-md-5 mt-3'>
                                <div className='h-100 logo-container d-flex justify-content-center align-items-center'>
                                    <img src={logo} alt='logo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* PIN Modal */}
                <div className={`modal-overlay ${showModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="loanModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">ENTER PIN</h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    aria-label="Close" 
                                    onClick={
                                        () => { 
                                            setData({
                                                ...data,
                                                pin: '',
                                                isSubmitting: false,
                                                errorMessage: null,
                                                successMessage: null
                                            });
                                            closeModal();
                                        }
                                    }
                                    disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <form onSubmit={transferFund}>
                                        <div className="form-group">
                                            {/* <label className="">OTP<sub>(sent to your email...)</sub></label> */}
                                            <label className="">PIN<sub>(Don't have one yet? Create your transaction pin on the Settings tab...)</sub></label>
                                            <input type="password" name="pin" className="form-control" onChange={handleChange} value={data.pin} />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "Confirm Transfer"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Successful Transaction Modal */}
                <div className={`modal-overlay ${showSuccessModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showSuccessModal ? 'show' : ''}`} id="successModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button 
                                    type="button" 
                                    className="close" 
                                    aria-label="Close" 
                                    onClick={
                                        () => closeModal()
                                    }
                                    disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="p-2 d-flex flex-column justify-content-center align-items-center">
                                    <h5 className="text-success">Transaction submitted</h5>
                                    <FontAwesomeIcon icon={faCheckCircle} color={theme.info} size="3x" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Loader */}
                {
                    data.isChecking ? 
                        (
                            <div className="d-flex align-items-center justify-content-center p-5 loader-wrapper">
                                <div className="loader"></div>
                            </div>
                        ) : null
                }
                
            </main>
        </DashboardLayout>
    )
}

export default Transfer;
