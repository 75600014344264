import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/DashboardLayout/DashboardLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';

import { faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../utils/theme';

import {apiUrl} from '../../../utils';

import './Transactions.css';

function Transaction() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        transactions: [],
        transaction: null,
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        refresh: false,
        date: '',
        searchItems: null
    };

    const [data, setData] = useState(initialState);

    const [ showModal, setShowModal ] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.transactions.slice(indexOfFirstItem, indexOfLastItem);

    const [currentItems, setCurrentItems] = useState();

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        if (data.searchItems) {
            const currentItems = data.searchItems.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        } else {
            const currentItems = data.transactions.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        }
    }, [data.transactions, currentPage, data.searchItems])

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  Transaction
        axios.get(`${apiBaseUrl}/transaction`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            setData({
                ...data,
                transactions: res.data.transactions,
                successMessage: null,
                errorMessage: null,
            });
        })
        .catch(err => console.log(err))

    }, [apiBaseUrl, data.refresh]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            [e.target.name]: e.target.value
        });

    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            const searchString = e.target.value.toLowerCase();
            const filteredItems = data.transactions.filter(item => item.sender.accountName.toLowerCase().includes(searchString) || item.sender.accountNumber.includes(searchString) || item.beneficiary.accountName.toLowerCase().includes(searchString) || item.beneficiary.accountNumber.includes(searchString));

            setCurrentPage(1);

            setData({
                ...data,
                searchItems: filteredItems
            })
        } else {
            setData({
                ...data,
                searchItems: null
            })
        }
    }

    const handleView = (id) => {

        const transaction = data.transactions.filter(transaction => transaction._id === id);
        openModal();
        // console.log(account)
        setData({
            ...data,
            transaction: transaction
        });
        // console.log(account)

    }

    const updateTransaction = async (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        if (data.date) {

            try {
                const res = await axios.patch(`${apiBaseUrl}/transaction/${data.transaction[0]._id}`, {
                    createdAt: data.date + 'T00:00:00'
                }, 
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
        
                if (res) {
                    closeModal();
                    setData({
                        ...data,
                        date: '',
                        refresh: !data.refresh,
                        isSubmitting: false,
                        errorMessage: null,
                        successMessage: 'Updated Successfully'
                    });
    
                    return;
                }
    
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'Oops! Something went wrong. Try again later.',
                    successMessage: null
                });
            } catch (error) {
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: null,
                    errorMessage: error.statusText || "Couldn't make the update. Try again later!"
                });
            }

        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty field',
                successMessage: null
            });
        }

    }

    const approveTransaction = async (id) => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/transaction/approve/${id}`, {}, 
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                setData({
                    ...data,
                    date: '',
                    refresh: !data.refresh,
                    isSubmitting: false,
                    errorMessage: null,
                    successMessage: 'Updated Successfully'
                });

                return;
            }

            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Oops! Something went wrong. Try again later.',
                successMessage: null
            });
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || "Couldn't make the update. Try again later!"
            });
        }

    }

    const openModal = () => {

        setShowModal(true);

    }

    const closeModal = () => {

        setShowModal(false);
        
    }

    return (
        <DashboardLayout activePage={4}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Transactions</div>
                    </div>
                </section>

                {/* Transaction */}
                <section className="transaction-section">
                    <div className="container">
                        <div className="form-group mb-3">
                            <input type="text" name="search" className="form-control" placeholder="Search" onChange={handleSearch} />
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Amount($)</th>
                                        <th scope="col">Sent By</th>
                                        <th scope="col">Received By</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((transaction, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td className="">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleView(transaction._id)}>Edit</button>
                                                        </td>
                                                        <td className="">{(new Date(transaction.createdAt)).toDateString()}</td>
                                                        <td className="text-right">{transaction.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="text-danger">
                                                            Trustcorp Bank<br />
                                                            {transaction.sender ? transaction.sender.accountNumber : ''}<br />
                                                            {transaction.sender ? transaction.sender.accountName : ''}
                                                        </td>
                                                        <td className="text-success">
                                                            {
                                                                transaction?.bankname ?
                                                                    `${transaction?.bankname}`
                                                                    :
                                                                    `Trustcorp Bank`
                                                            }<br />
                                                            {transaction?.beneficiaryNumber ? transaction.beneficiaryNumber : ''}
                                                            {transaction?.beneficiaryNumber && <br />}
                                                            {transaction?.beneficiaryNumber ? transaction.beneficiaryName : ''}
                                                            {transaction?.beneficiaryNumber && <br />}
                                                            {transaction?.beneficiary ? transaction.beneficiary.accountNumber : ''}
                                                            {transaction?.beneficiary && <br />}
                                                            {transaction?.beneficiary ? transaction.beneficiary.accountName : ''}
                                                            {transaction?.beneficiary && <br />}
                                                        </td>
                                                        <td className=''>
                                                            {
                                                                transaction?.approved ?
                                                                    (<span className='text-success'>
                                                                        Approved&nbsp;
                                                                        <FontAwesomeIcon 
                                                                            className="icon"
                                                                            icon={faCheckCircle}
                                                                            color={theme.primary}
                                                                        />
                                                                    </span>)
                                                                    :
                                                                    (
                                                                        <button 
                                                                            type='button' 
                                                                            className='btn btn-primary'
                                                                            onClick={() => approveTransaction(transaction?._id)}
                                                                        >
                                                                            Approve
                                                                        </button>
                                                                    )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={ data.searchItems ? data.searchItems.length : data.transactions.length } 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>

                {/* TRANSACTION Modal */}
                <div className={`modal-overlay ${showModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="editModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">TRANSACTION</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="modal-footer">
                                        {
                                            data.errorMessage && (
                                                <div className="alert alert-danger">{data.errorMessage}</div>
                                            )
                                        }
                                        <form onSubmit={updateTransaction} noValidate>
                                            <div className="form-group">
                                                <label className="">Change Date</label>
                                                <input type="date" name="date" className="form-control" onChange={handleChange} value={data.date} />
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "UPDATE"}</button>
                                        </form>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr className="">
                                                    <th className="">AMOUNT($)</th>
                                                    <td className="">{data.transaction ? data.transaction[0].amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">SENT BY</th>
                                                    <td className="">
                                                        {data.transaction ? data.transaction[0].sender ? data.transaction[0].sender.accountNumber : '' : ''}<br />
                                                        {data.transaction ? data.transaction[0].sender ? data.transaction[0].sender.accountName : ''  : ''}
                                                    </td>
                                                </tr>
                                                {/* <tr className="">
                                                    <th className="">RECEIVED BY</th>
                                                    <td className="">
                                                        {data.transaction ? data.transaction[0].beneficiary ? data.transaction[0].beneficiary.accountNumber : '' : ''}<br />
                                                        {data.transaction ? data.transaction[0].beneficiary ? data.transaction[0].beneficiary.accountName : ''  : ''}
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </DashboardLayout>
    )
}

export default Transaction;
