import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';
import { faCheckCircle, faHourglassStart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../utils/theme';

import {apiUrl} from '../../../utils';

import './History.css';

function History() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        account: null,
        transactions: [],
        searchItems: null
    };

    const [data, setData] = useState(initialState);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.transactions.slice(indexOfFirstItem, indexOfLastItem);

    const [currentItems, setCurrentItems] = useState();

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        if (data.searchItems) {
            const currentItems = data.searchItems.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        } else {
            const currentItems = data.transactions.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        }
    }, [data.transactions, currentPage, data.searchItems])

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        // Account
        axios.get(`${apiBaseUrl}/account/user/${user._id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            // console.log(res.data)
            setData({
                ...data,
                account: res.data.account
            });
        })
        .catch(err => console.log(err))
        
    }, [apiBaseUrl]);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  History
        if (data.account) {
            axios.get(`${apiBaseUrl}/transaction/account/${data.account._id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                setData({
                    ...data,
                    transactions: res.data.transactions
                });
            })
            .catch(err => console.log(err))
        }
    }, [apiBaseUrl, data.account]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            const searchString = e.target.value.toLowerCase();
            const filteredItems = data.transactions.filter(item => item.beneficiary.accountName.toLowerCase().includes(searchString) || item.beneficiary.accountNumber.includes(searchString));

            setCurrentPage(1);

            setData({
                ...data,
                searchItems: filteredItems
            })
        } else {
            setData({
                ...data,
                searchItems: null
            })
        }
    }

    return (
        <DashboardLayout activePage={4}>
            <main className="history-wrapper">
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Transaction History</div>
                    </div>
                </section>

                {/* History */}
                <section className="history-section">
                    <div className="container">
                        <div className="form-group mb-3">
                            <input type="text" name="search" className="form-control" placeholder="Search" onChange={handleSearch} />
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                {/* <thead>
                                    <tr>
                                        <th scope="col">DATE</th>
                                        <th scope="col">DESCRIPTION</th>
                                        <th scope="col">AMOUNT($)</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((transaction, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        {/* <td className="">{i + 1}</td> */}
                                                        <td className="">{(new Date(transaction.createdAt)).toDateString()}</td>
                                                        <td className="">{transaction.description}</td>
                                                        <td className="text-right">{transaction.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="">
                                                            {
                                                                transaction.senderId === data.account._id ? 
                                                                    (<span className="text-danger">DEBIT</span>) : (<span className="text-success">CREDIT</span>)
                                                            }
                                                        </td>
                                                        <td className="">
                                                            {
                                                                transaction?.beneficiary ? 
                                                                    transaction.senderId === data.account._id ? 
                                                                        (<span className="text-danger">
                                                                            {
                                                                                transaction.beneficiary && 
                                                                                    (`to: ${transaction?.bankname} / ${transaction.beneficiary.accountName} [${transaction.beneficiary.accountNumber}]`)
                                                                            }
                                                                        </span>) 
                                                                        : (<span className="text-success">
                                                                            {
                                                                                transaction.sender && 
                                                                                    (`from: ${transaction?.bankname} / ${transaction.sender.accountName} [${transaction.sender.accountNumber}]`)
                                                                            }
                                                                        </span>)
                                                                    :
                                                                    transaction.senderId === data.account._id ? 
                                                                        (<span className="text-danger">
                                                                            {
                                                                                transaction?.bankname && 
                                                                                    (`to: ${transaction?.bankname} / ${transaction?.beneficiaryName} [${transaction.beneficiaryNumber}]`)
                                                                            }
                                                                        </span>) 
                                                                        : (<span className="text-success">
                                                                            {
                                                                                transaction?.sender && 
                                                                                    (`from: ${transaction.sender.accountName} [${transaction.sender.accountNumber}]`)
                                                                            }
                                                                        </span>)
                                                            }
                                                        </td>
                                                        <td className=''>
                                                            {
                                                                transaction?.approved ?
                                                                    (<span className='text-success'>
                                                                        Delivered&nbsp;
                                                                        <FontAwesomeIcon 
                                                                            className="icon"
                                                                            icon={faCheckCircle}
                                                                            color={theme.primary}
                                                                        />
                                                                    </span>)
                                                                    :
                                                                    (<span className='text-danger'>
                                                                        Pending...&nbsp;
                                                                        <FontAwesomeIcon 
                                                                            className="icon"
                                                                            icon={faHourglassStart}
                                                                            color={theme.bgRed}
                                                                        />
                                                                    </span>)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={ data.searchItems ? data.searchItems.length : data.transactions.length } 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default History;
