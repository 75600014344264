import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import axios from 'axios';
import {theme} from '../../../utils/theme';

import { faTimes, faPencilAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Settings.css';

function Settings() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        user: null,
        account: null,
        mobile: '',
        address: '',
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        refresh: false,
        pin: '',
        confirmPin: '',
        email: '',
        code: '',
        image: null,
        password: '',
        confirmPassword: ''
    };

    const [data, setData] = useState(initialState);

    const [ showProfileModal, setShowProfileModal ] = useState(false);
    const [ showCreatePinModal, setShowCreatePinModal ] = useState(false);
    const [ showUpdatePinModal, setShowUpdatePinModal ] = useState(false);
    const [ showChangePasswordModal, setShowChangePasswordModal ] = useState(false);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        // Account
        axios.get(`${apiBaseUrl}/account/user/${user._id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            setData({
                ...data,
                account: res.data.account,
                mobile: res.data.account.user ? res.data.account.user.mobile : '',
                address: res.data.account.user ? res.data.account.user.address : '',
                user: user,
                successMessage: null,
                errorMessage: null,
            });
        })
        .catch(err => console.log(err))
        
    }, [apiBaseUrl, data.refresh]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            [e.target.name]: e.target.value
        });

    };

    const profileUpdate = (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        if (data.mobile !== '' && data.address !== '' && data.user) {
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: null,
                successMessage: null
            });
    
            axios.patch(`${apiBaseUrl}/user/${data.user._id}`, {
                mobile: data.mobile,
                address: data.address
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                // console.log(res.data)
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: 'Profile updated successfully',
                    errorMessage: null,
                    mobile: '',
                    address: '',
                    refresh: !data.refresh
                });
                closeModal();
            })
            .catch(error => {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: error.message || error.statusText,
                    successMessage: null
                });
                
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields',
                successMessage: null
            });
        }

    };

    // const loadFile = (e) => {
    //     let image = document.getElementById('output');
    //     if (e.target.files[0]) {
    //         image.src = URL.createObjectURL(e.target.files[0]);

    //         setData({
    //             ...data,
    //             image: e.target.files[0]
    //         })
    //     }
    // }

    // const saveImage = (e) => {
    //     e.preventDefault();
    //     const user = cookie.session.user;
    //     const token = cookie.session.token;

    //     const formData = new FormData();

    //     setData({
    //         ...data,
    //         isSubmitting: true,
    //         errorMessage: null,
    //         successMessage: null
    //     });

    //     if(data.image) {
    //         if (data.image.size <= (1024 * 1024 * 5)) {
    
    //             formData.append('image', data.image);
    //             axios({
    //                 method: 'put',
    //                 url: `${apiBaseUrl}/user/${user._id}/upload-avatar`,
    //                 data: formData,
    //                 headers: {
    //                     'Authorization': 'Bearer ' + token,
    //                  }
    //                 })
    //                 .then( async res => {

    //                     const image = res?.data?.path;
    //                     // console.log(image);
                        
    //                     try {
    //                         const saveRes = await axios.patch(`${apiBaseUrl}/user/${user._id}/save-avatar`,{
    //                             image: image
    //                         },
    //                         {
    //                             headers: {
    //                                 'Authorization': 'Bearer ' + token,
    //                              }
    //                         });
                            
    //                         if (saveRes) {
    //                             setData({
    //                                 ...data,
    //                                 isSubmitting: false,
    //                                 successMessage: 'Image upload successful',
    //                                 errorMessage: null,
    //                                 image: null,
    //                                 refresh: !data.refresh
    //                             });
    //                         }
    //                     } catch (error) {
    //                         setData({
    //                             ...data,
    //                             isSubmitting: false,
    //                             successMessage: null,
    //                             errorMessage: 'Image Upload failed...try again',
    //                         });
    //                     }
    //                 })
    //                 .catch(err => {
    //                     setData({
    //                         ...data,
    //                         isSubmitting: false,
    //                         successMessage: null,
    //                         errorMessage: 'Image Upload failed...try again',
    //                     });
    //                 });
    //         } else {

    //             setData({
    //                 ...data,
    //                 isSubmitting: false,
    //                 successMessage: null,
    //                 errorMessage: 'Image should not be grether than 5mb',
    //                 refresh: !data.refresh
    //             });
    //         }
    //     } else {
    //         setData({
    //             ...data,
    //             isSubmitting: false,
    //             successMessage: null,
    //             errorMessage: 'Upload image'
    //         });
    //     }
    // }

    const createPin = (e) => {
        e.preventDefault();
        // console.log(data);
        const user = cookie.session.user;
        const token = cookie.session.token;

        if (data.pin !== '' && data.confirmPin !== '' && data.user) {
            
            if (data.pin === data.confirmPin) {
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null,
                    successMessage: null
                });
        
                axios.patch(`${apiBaseUrl}/user/${data.user._id}/create-pin`, {
                    pin: data.pin,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(res => {
                    // console.log(res.data)
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Pin created successfully',
                        errorMessage: null,
                        pin: '',
                        confirmPin: ''
                    });
                    closeModal();
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: (error?.response?.data?.message) || error.statusText,
                        successMessage: null
                    });
                    
                });
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "The pins don't match",
                    successMessage: null
                });
            }
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields',
                successMessage: null
            });
        }

    };

    const changePassword = (e) => {
        e.preventDefault();
        // console.log(data);
        const user = cookie.session.user;
        const token = cookie.session.token;

        if (data.password !== '' && data.confirmPassword !== '' && data.user) {
            
            if (data.password === data.confirmPassword) {
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null,
                    successMessage: null
                });
        
                axios.patch(`${apiBaseUrl}/user/${data.user._id}/change-password`, {
                    password: data.password,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(res => {
                    // console.log(res.data)
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Password changed successfully',
                        errorMessage: null,
                        password: '',
                        confirmPassword: ''
                    });
                    closeModal();
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: (error?.response?.data?.message) || error.statusText,
                        successMessage: null
                    });
                    
                });
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "The passwords don't match",
                    successMessage: null
                });
            }
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields',
                successMessage: null
            });
        }

    };

    // const sendCode = (e) => {
    //     e.preventDefault();
    //     const user = cookie.session.user;
    //     const token = cookie.session.token;

    //     if (data.user) {
    //         setData({
    //             ...data,
    //             isSubmitting: true,
    //             errorMessage: null,
    //             successMessage: null
    //         });
    
    //         axios.post(`${apiBaseUrl}/user/forgot-pin`, {
    //             email: data.user.email
    //         },
    //         {
    //             headers: {
    //                 'Authorization': 'Bearer ' + token
    //             }
    //         })
    //         .then(res => {
    //             // console.log(res.data)
    //             setData({
    //                 ...data,
    //                 isSubmitting: false,
    //                 successMessage: `Successful. Verification code sent to your email.`,
    //                 errorMessage: null,
    //                 refresh: !data.refresh
    //             });
    //         })
    //         .catch(error => {
    //             setData({
    //                 ...data,
    //                 isSubmitting: false,
    //                 errorMessage: `Failed to send code. Try again!`,
    //                 successMessage: null
    //             });
                
    //         });
    //     } else {
    //         setData({
    //             ...data,
    //             isSubmitting: false,
    //             errorMessage: "Something went wrong. Try again later!",
    //             successMessage: null
    //         });
    //     }

    // };

    // const changePin = (e) => {
    //     e.preventDefault();
    //     const user = cookie.session.user;
    //     const token = cookie.session.token;

    //     if (data.pin !== '' && data.code !== '' && data.user) {
    //         setData({
    //             ...data,
    //             isSubmitting: true,
    //             errorMessage: null,
    //             successMessage: null
    //         });
    
    //         axios.put(`${apiBaseUrl}/user/reset-pin`, {
    //             email: data.user.email,
    //             code: data.code,
    //             newPin: data.pin
    //         },
    //         {
    //             headers: {
    //                 'Authorization': 'Bearer ' + token
    //             }
    //         })
    //         .then(res => {
    //             // console.log(res.data)
    //             setData({
    //                 ...data,
    //                 isSubmitting: false,
    //                 successMessage: `Password reset successful`,
    //                 errorMessage: null,
    //                 code: '',
    //                 pin: '',
    //                 refresh: !data.refresh
    //             });
    //             closeModal();
    //         })
    //         .catch(error => {
    //             setData({
    //                 ...data,
    //                 isSubmitting: false,
    //                 errorMessage: `Password reset failed`,
    //                 successMessage: null
    //             });
                
    //         });
    //     } else {
    //         setData({
    //             ...data,
    //             isSubmitting: false,
    //             errorMessage: "Empty Field",
    //             successMessage: null
    //         });
    //     }

    // };

    const openProfileModal = () => {

        setShowProfileModal(true);

    }

    const openCreateModal = () => {

        setShowCreatePinModal(true);

    }

    const openChangePasswordModal = () => {

        setShowChangePasswordModal(true);

    }

    // const openUpdateModal = () => {

    //     setShowUpdatePinModal(true);

    // }

    const closeModal = () => {

        setShowProfileModal(false);
        setShowCreatePinModal(false);
        setShowUpdatePinModal(false);
        setShowChangePasswordModal(false);
        
    }

    return (
        <DashboardLayout activePage={4}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Settings</div>
                    </div>
                </section>

                {/* Profile */}
                <section className="profile-section">
                    <div className="container">
                        {
                            data.successMessage && (
                                <div className="alert alert-success">{data.successMessage}</div>
                            )
                        }
                        {
                            data.errorMessage && (
                                <div className="alert alert-danger">{data.errorMessage}</div>
                            )
                        }
                        <div className="row">
                            <div className="col-md-5 mt-3">
                                <p className=''>
                                    <FontAwesomeIcon 
                                        icon={faInfoCircle}
                                        className=''
                                        color={theme.primary}
                                    />&nbsp;
                                    You need a transaction pin to initiate transactions.<br />
                                    <sub>Don't forget your transaction pin</sub>
                                </p>
                                <p className="">
                                    {
                                        data.account ? 
                                            data.account.user ? 
                                                !data.account.user.pin ? 
                                                    (
                                                        <>
                                                            <button type="button" className="btn btn-primary" onClick={() => openCreateModal()}>Create Transaction PIN</button>
                                                        </>
                                                    ) 
                                                    : (
                                                        <button type="button" className="btn btn-primary" onClick={() => openCreateModal()}>Change PIN</button>
                                                    )
                                                : null 
                                            : null 
                                    }
                                </p>
                                <p className=''>
                                    <FontAwesomeIcon 
                                        icon={faInfoCircle}
                                        className=''
                                        color={theme.primary}
                                    />&nbsp;
                                    Want to change your password?<br />
                                    <sub>Click the button below to change your password.</sub>
                                </p>
                                <button type="button" className="btn btn-primary" onClick={() => openChangePasswordModal()}>Change Password</button>
                            </div>
                            <div className="col-md-7 mt-3">
                                <div className="w-100 h-100">
                                    <div className="px-2">
                                        <div className="d-flex justify-content-between align-items-center mb-4" style={{cursor: 'pointer'}}>
                                            <h5 className="font-weight-bold">Profile</h5>
                                            <FontAwesomeIcon 
                                                icon={faPencilAlt} 
                                                style={{color: `${theme.secondary}`}} 
                                                onClick={() => openProfileModal()}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">First Name:</p>
                                            <p className="">{data.account ? data.account.user ? data.account.user.firstName.toUpperCase() : '-' : '-' }</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Last Name:</p>
                                            <p className="">{data.account ? data.account.user ? data.account.user.lastName.toUpperCase() : '-' : '-' }</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Email:</p>
                                            <p className="">{data.account ? data.account.user ? data.account.user.email : '-' : '-' }</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Mobile:</p>
                                            <p className="">{data.account ? data.account.user ? data.account.user.mobile : '-' : '-' }</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Account Number:</p>
                                            <p className="">{data.account ? data.account.accountNumber : '-'}</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Account Type:</p>
                                            <p className="">{data.account ? data.account.accountType : '-'}</p>
                                        </div>
                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-bold mr-1 tag">Address:</p>
                                            <p className="">{data.account ? data.account.user ? data.account.user.address : '-' : '-' }</p>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Edit Profile Modal */}
                <div className={`modal-overlay ${showProfileModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showProfileModal ? 'show' : ''}`} id="profileModal" tabIndex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">UPDATE PROFILE</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <form onSubmit={profileUpdate}>
                                        <div className="form-group">
                                            <label className="">Mobile Number</label>
                                            <input type="text" name="mobile" className="form-control" onChange={handleChange} defaultValue={data.mobile} />
                                        </div>
                                        <div className="form-group">
                                            <label className="">Address</label>
                                            <input type="text" name="address" className="form-control" onChange={handleChange} defaultValue={data.address} />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => closeModal()} disabled={data.isSubmitting}>CANCEL</button>
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SAVE CHANGES"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Create PIN Modal */}
                <div className={`modal-overlay ${showCreatePinModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showCreatePinModal ? 'show' : ''}`} id="createPinModal" tabIndex="-1" aria-labelledby="createPinModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">Create Transaction Pin</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <form onSubmit={createPin}>
                                        <div className="form-group">
                                            <label className="">PIN</label>
                                            <input type="password" name="pin" className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="">Confirm PIN</label>
                                            <input type="password" name="confirmPin" className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => closeModal()} disabled={data.isSubmitting}>CANCEL</button>
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SAVE CHANGES"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Change PIN Modal */}
                {/* <div className={`modal-overlay ${showUpdatePinModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showUpdatePinModal ? 'show' : ''}`} id="changePinModal" tabIndex="-1" aria-labelledby="changePinModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">CHANGE TRANSACTION PIN</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <p className="text-right">
                                        <button type="button" className="btn btn-secondary mr-2" data-toggle="modal" data-target="#changePinModal" onClick={() => sendCode()} disabled={data.isSubmitting}>{data.isSubmitting ? 'Loading...' : 'SEND CODE'}</button>
                                    </p>
                                    {
                                        data.successMessage && (
                                            <div className="alert alert-danger">{data.successMessage}</div>
                                        )
                                    }
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <form onSubmit={changePin}>
                                        <div className="form-group">
                                            <label className="">Verification Code(from your email)</label>
                                            <input type="text" name="code" className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="">New Pin</label>
                                            <input type="text" name="pin" className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => closeModal()} disabled={data.isSubmitting}>CANCEL</button>
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SUBMIT"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Change Password Modal */}
                <div className={`modal-overlay ${showChangePasswordModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showChangePasswordModal ? 'show' : ''}`} id="createPinModal" tabIndex="-1" aria-labelledby="createPinModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">Reset Password</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <form className="w-100" onSubmit={changePassword}>
                                        <div className="form-group mb-3">
                                            <input type='password' name="password" className="form-control" placeholder="New Password" onChange={handleChange} value={data.password} noValidate />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm password" onChange={handleChange} value={data.confirmPassword} noValidate />
                                        </div>
                                        <p className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SUBMIT"}</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default Settings;
